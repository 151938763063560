<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <v-text-field
            id="input-pcode"
            v-model="qrKey"
            label="이동 파레트번호"
            placeholder="QR 스캔"
            clearable
            autocomplete="off"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>

      <div style="margin-top:4px; background-color:black;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsInputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          height="140"
          :focused-row-enabled="true"
          :focused-row-key.sync="focusedRowKey"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @focused-row-changed="onSelectionChanged"
        >
          <DxColumn
            :allow-editing="false"
            caption="NO"
            data-field="입고키"
            sort-order="desc"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="재고"
            data-field="현재수량"
            format="#,##0"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="유효일"
            data-field="유효일"
            data-type="date"
            format="yy/MM/dd"
            :min-width="100"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <div style="margin-top:14px;">
        <v-row dense>
          <v-col
            :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
          >
            <v-text-field
              id="input-pcode-target"
              v-model="qrKeyTarget"
              label="대상 파레트번호"
              placeholder="QR 스캔"
              clearable
              autocomplete="off"
              @keyup.enter.prevent="onEnterPcodeTarget"
            />
          </v-col>
          <v-col
            v-if="$store.getters.getMobileModel !== 'PDA'"
            class="pb-0 pt-4 pl-0"
            cols="1"
          >
            <v-icon
              @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
            >
              mdi-camera
            </v-icon>
          </v-col>
        </v-row>

        <div style="margin-top:4px; background-color:black;">
          <dx-data-grid
            ref="refGoodsInputGridTarget"
            :data-source="goodsInputListInfoTarget"
            :show-borders="true"
            :show-row-lines="false"
            :show-column-lines="true"
            :hover-state-enabled="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :allow-column-reordering="true"
            :row-alternation-enabled="true"
            height="140"
            :focused-row-enabled="true"
            key-expr="seqId"
          >
            <DxColumn
              :allow-editing="false"
              caption="NO"
              data-field="입고키"
              sort-order="desc"
              :min-width="60"
            />
            <DxColumn
              :allow-editing="false"
              caption="품번"
              data-field="품번"
              :min-width="100"
            />
            <DxColumn
              :allow-editing="false"
              caption="재고"
              data-field="현재수량"
              format="#,##0"
              :min-width="80"
            />
            <DxColumn
              :allow-editing="true"
              caption="유효일"
              data-field="유효일"
              data-type="date"
              format="yy/MM/dd"
              :min-width="100"
            />
            <dx-paging :enabled="false" />
            <dx-scrolling
              mode="virtual"
              show-scrollbar="always"
              :use-native="true"
            />
            <dx-sorting mode="multiple" />
          </dx-data-grid>
        </div>
      </div>
      <v-row
        dense
      >
        <v-col
          cols="12"
        >
          <v-text-field
            id="input-move-qty"
            v-model="qtyTarget"
            label="이동수량"
            placeholder="이동수량"
            :readonly="false"
            clearable
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="showSaveButton"
        dense
        class="mt-2"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onClickSave"
          >
            재고 이동
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import HttpService from '../../share/service/HttpService'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting } from 'devextreme-vue/data-grid'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    QrCodeScanComponent,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting
  },
  data: function () {
    return {
      isShow위치박스: false,
      isLoading: false,
      userInfo: null,
      qrKey: ' ',
      pCodeScan: 0,
      selectedRow: {},
      goodsInputListInfo: null,
      focusedRowKey: null,
      // qrcode scan
      showQrScaner: 'none',
      // target pallet
      qrKeyTarget: null,
      goodsInputListInfoTarget: null,
      qtyTarget: null,
      showSaveButton: false
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    HttpService.reqPost(null, '/isLogin')
  },
  mounted () {
    this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.qrKey = null
      this.qrKeyTarget = null
      this.pCodeScan = 0
      this.goodsInputListInfo = null
      this.focusedRowKey = null
      this.selectedRow = {}
      this.showSaveButton = false
      this.qtyTarget = null
      this.goodsInputListInfoTarget = null
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
      }, 100)
    },
    onEnterLoation (e) {
      this.qrLocation = e
      if (this.qrLocation === null) return
      this.showQrScaner = 'none'
      this.isLoading = true
      this.updateLocation(this.qrLocation, this.qrKey, ConstDef.자재입고)
    },
    onEnterPcode (e) {
      let split = null
      try {
        split = AppLib.base64DecodeSubstring(this.qrKey)
      } catch {
        this.$snotify.error('제품정보를 확인할 수 없습니다.')
        this.initInput()
        return
      }
      this.pCodeScan = 1
      this.qrKey = parseInt(split)
      this.focusedRowKey = null
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptByKeyAndInput', { 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
          console.log(this.goodsInputListInfo)
          if (this.goodsInputListInfo.length > 0) {
            if (this.goodsInputListInfo.length === 1) {
              this.focusedRowKey = this.goodsInputListInfo[0].seqId
              setTimeout(() => {
                this.$Q('#input-pcode-target').focus()
              }, 100)
            } else {
              this.$snotify.info('이동할 품목을 선택하여 주세요')
            }
          } else {
            this.$snotify.info('해당 파레트번호로 검색된 품목이 없습니다.')
            this.initInput()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      if (this.pCodeScan === 0) {
        this.qrKey = result
        this.pCodeScan = 1
        this.onEnterPcode(null)
      } else {
        this.qrKeyTarget = result
        this.onEnterPcodeTarget(null)
      }
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
        this.qtyTarget = this.selectedRow.현재수량
        this.$Q('#input-pcode-target').focus()
      }
    },
    // target area
    onClickSave () {
      if (this.qtyTarget === 0) {
        this.$snotify.info('이동할 재고가 없습니다.')
        return
      }
      if (this.qtyTarget > this.selectedRow.현재수량) {
        this.$snotify.info('이동할 수량이 현재고 보다 많습니다..')
        return
      }
      const param = [
        {
          입고키: this.qrKey,
          품번: this.selectedRow.품번,
          수량: this.qtyTarget,
          제안ID: this.selectedRow.발주번호,
          입출고구분: ConstDef.자재출고,
          유효일: this.selectedRow.유효일,
          동종재고코드: this.selectedRow.동종재고코드
        },
        {
          입고키: this.qrKeyTarget,
          품번: this.selectedRow.품번,
          수량: this.qtyTarget,
          제안ID: this.selectedRow.발주번호,
          입출고구분: ConstDef.자재입고,
          유효일: this.selectedRow.유효일,
          동종재고코드: this.selectedRow.동종재고코드
        }
      ]
      this.isLoading = true
      this.$_sp.runUpdateProc('spSaveStorageResult', param)
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          this.initInput()
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterPcodeTarget () {
      if (this.selectedRow === null) {
        this.$snotify.info('이동할 품목을 선택하여 주세요')
        return
      }
      let split = null
      try {
        split = AppLib.base64DecodeSubstring(this.qrKeyTarget)
      } catch {
        this.$snotify.error('제품정보를 확인할 수 없습니다.')
        this.$Q('#input-pcode-target').focus()
        return
      }
      this.qrKeyTarget = parseInt(split)
      if (this.qrKey === this.qrKeyTarget) {
        this.$snotify.info('타겟 파레트 번호가 소스 번호와 동일합니다.')
        this.qrKeyTarget = null
        return
      }
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptByKeyAndInput', { 입고키: this.qrKeyTarget, 입출고구분: ConstDef.자재입고 })
        .then((data) => {
          this.goodsInputListInfoTarget = this.$_sp.MakeModel(data)
          console.log(this.goodsInputListInfoTarget)
          if (this.goodsInputListInfoTarget.length === 0) {
            this.$snotify.info('해당 파레트번호는 새로운 파레트 번호 입니다.')
          } else {
            const where = this.$_.where(this.goodsInputListInfoTarget, { 품번: this.selectedRow.품번 })
            if (where.length > 0) {
              const temp = this.$_.findWhere(where, { 동종재고코드: this.selectedRow.동종재고코드 })
              if (temp === undefined) {
                this.$snotify.info('대상 파레트에 동종재고코드가 다른 품목이 이미 등록되어 있어 재고 이동이 불가합니다.')
                return
              }
            }
          }
          this.showSaveButton = true
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }

  }
}
</script>

<style lang="scss">

</style>
